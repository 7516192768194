import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/ahafood/src/components/fb-login-btn.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/ahafood/src/layouts/main/main-layout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollArea"] */ "/app/packages/ui/src/components/ui/scroll-area.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/ui/separator.tsx");
